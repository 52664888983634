var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qr-code" }, [
    _c("img", {
      staticClass: "qr-code-img",
      attrs: { src: _vm.imgsrc, alt: "Qr Code per ritirare l'ordine" },
      on: {
        click: function($event) {
          _vm.dialog = true
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }