<template>
  <v-container class="gift-card-report px-3 pt-12 px-0">
    <h2 class="text-center default--text">
      {{ $t("giftCard.reports.title") }}
    </h2>
    <div class="d-flex report-header mt-6">
      <div class="w-50">{{ $t("giftCard.reports.date") }}</div>
      <div class="flex-grow-1">
        {{ $t("giftCard.reports.amount") }}
      </div>
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="2"
    ></v-progress-linear>
    <div
      v-for="(report, index) in reports"
      :key="'report-' + index"
      class="d-flex report-row"
    >
      <div class="w-50">{{ $dayjs(report.pdataop).format("DD/MM/YYYY") }}</div>
      <div class="flex-grow-1 font-weight-semibold">
        {{ $n(parseFloat(report.valore), "currency") }}
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.gift-card-report {
  .report-row {
    border-bottom: 1px solid #e2e2e2;
    font-size: 16px;
    padding: 8px 0;
  }
  .report-header {
    border-bottom: 1px solid #e2e2e2;
    font-size: 14px;
    padding: 6px 0;
    font-weight: 600;
  }
}
</style>

<script>
import AbbondanzaGiftCardService from "@/service/abbondanzaGiftCardService";
export default {
  name: "GiftCardReport",
  props: {
    cardCode: { type: String, required: true }
  },
  data() {
    return {
      cardData: {},
      loading: false
    };
  },
  computed: {
    reports() {
      return this.cardData.info?.riga?.rigaGift;
    }
  },
  methods: {
    getReports() {
      this.loading = true;
      AbbondanzaGiftCardService.getGiftCardReport(this.cardCode)
        .then(data => {
          this.cardData = data.value;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async created() {
    this.getReports();
  }
};
</script>
